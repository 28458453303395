import React from 'react'
import Layout from 'components/layout/layout'
import Helment from 'react-helmet'
import LogoB from 'images/svgs/bantu-logo-b.svg'
import 'styles/pages/about.scss'

// center and 35px padding
const AboutPage = () => (
  <Layout isCentered>
    <Helment>
      <title>About</title>
    </Helment>
    <section className="about-container opaque-container br-small">
      <h2 className="margin-y-4 text-center">What is Bantu?</h2>
      <p className="margin-y-4 container-75">
        Bantu is a <b>Black-owned business directory</b> created so that
        everyday people can easily and efficiently locate, review, and rate
        Black-owned businesses. With that, our team is dedicated to making
        shopping Black-owned the standard by way of{' '}
        <b>
          our mission: to feature Black-owned businesses and highlight Black
          excellence
        </b>
        . The Bantu team will accomplish our goal of bringing Black-owned
        businesses to the forefront by fusing Black cultural-regality and modern
        design, allowing users to love, know, and trust Bantu for everything
        Black-owned.
      </p>
      <p className="margin-y-4 container-75">
        Our three target cities are New York, Memphis, and Atlanta. Not located
        in any of those cities? Don’t worry! While{' '}
        <b>Black business is our base, Black excellence is our bar</b> so our
        team is regularly expanding and updating our business data in real time
        as Black-owned businesses are submitted.
      </p>
      <p className="container-75 margin-y-6">
        As Bantu develops the final website and directory, we are also looking
        for potential collaborations with brands that align with our mission and
        vision. If that’s you, please feel free to reach us directly at{' '}
        <a
          href="mailto:contactus@bantu.market"
          className="email-link"
          aria-label="Reach us directly at contactus@bantu.market"
        >
          contactus@bantu.market
        </a>
        .
      </p>
      <p>Sincerely,</p>
      <p className="margin-y-2">The Bantu Team</p>
      <LogoB className="about-logo-b" />
    </section>
  </Layout>
)

export default AboutPage
